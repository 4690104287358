window.StatCounter = () => {
	return {
		counter: 0,
		displayValue: '0',
		animate(finalCount) {
			let time = 1500; /* Time in milliseconds */
			let interval;
			if (finalCount <= 100) {
				interval = 90;
			} else {
				interval = 9;
			}
			let step = Math.floor((finalCount * interval) / time);
			let timer = setInterval(() => {
				this.counter = this.counter + step;
				if (this.counter >= finalCount + step) {
					this.counter = finalCount;
					clearInterval(timer);
					timer = null;
					this.displayValue = this.counter.toLocaleString('en-US');
					return;
				}
				this.displayValue = this.counter.toLocaleString('en-US');
			}, interval);
		},
	};
};
